import { map, flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import RecentPostsListItem from '../recent-posts-list-item';
import { shouldRenderCover } from '../../../app/services/get-post-cover';
import withCardBorderColor from '../../hoc/with-card-border-color';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import { isQACategoriesExperimentEnabled } from '../../../app/selectors/experiments-selectors';
import { connect } from '../../../common/components/runtime-context';
import styles from './recent-posts-list.scss';

export const RecentPostsList = ({
  posts = [],
  borderColor,
  borderWidth,
  isPostNumbersEnabled,
  handleLikeClick,
  isQACategoriesEnabled,
}) => {
  const itemClassName = classNames(
    styles.listItem,
    'forum-card-background-color',
    'recent-posts-list__list-item',
    {
      [styles.withFooter]: isPostNumbersEnabled,
      [styles.QACategories]: isQACategoriesEnabled,
    },
  );

  return (
    <div
      className={classNames(styles.container, {
        [styles.QACategories]: isQACategoriesEnabled,
      })}
    >
      {map(posts, post => (
        <div className={itemClassName} style={{ borderColor, borderWidth }} key={post._id}>
          <RecentPostsListItem
            post={post}
            isPostNumbersEnabled={isPostNumbersEnabled}
            handleLikeClick={handleLikeClick}
            withCover={shouldRenderCover(post)}
            isQACategoriesEnabled={isQACategoriesEnabled}
          />
        </div>
      ))}
    </div>
  );
};

RecentPostsList.propTypes = {
  posts: PropTypes.array,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.number,
  isPostNumbersEnabled: PropTypes.bool,
  handleLikeClick: PropTypes.func,
};

const mapRuntimeToProps = state => ({
  isQACategoriesEnabled: isQACategoriesExperimentEnabled(state),
});
// prettier-ignore
export default flowRight(
  withCardBorderColor,
  withCardBorderWidth,
  connect(mapRuntimeToProps)
)(RecentPostsList);
