import { findKey, get } from 'lodash';
import {
  IS_HEADER_ENABLED_PATH,
  IS_LOGIN_WIDGET_ENABLED_PATH,
  IS_RCE_IMAGE_ENABLED_PATH,
  IS_RCE_HTML_ENABLED_PATH,
  IS_RCE_VIDEO_ENABLED_PATH,
  IS_RCE_DIVIDER_ENABLED_PATH,
  IS_RCE_CODE_BLOCK_ENABLED_PATH,
  IS_RCE_GIPHY_ENABLED_PATH,
  IS_RCE_EMOJI_ENABLED_PATH,
  IS_VIEWS_COUNT_ENABLED_PATH,
  IS_LIST_HASHTAGS_ENABLED_PATH,
  IS_SOCIAL_SHARING_LINKS_ENABLED_PATH,
  IS_MAIN_PAGE_HEADER_ENABLED_PATH,
  FORUM_STRUCTURE_PATH,
  HEADER_HEIGHT_PATH,
  MAIN_PAGE_HEADER_HEIGHT_PATH,
  CATEGORY_TEXT_ALIGNMENT_PATH,
  MAIN_PAGE_TEXT_ALIGNMENT_PATH,
  POST_SORTING_PATH,
  IS_SHOW_SUBCATEGORIES_ENABLED_PATH,
  IS_BADGES_ENABLED_PATH,
  IS_FOOTER_POSTS_ENABLED_PATH,
  IS_RCE_FILE_UPLOAD_ENABLED_PATH,
  CARD_SIZE_PATH,
} from '../constants/wix-params';
import { getAppSettingsValue } from '../../common/store/app-settings/app-settings-selectors';
import { isMemberAreaActive } from './member-area-selectors';
import { getCategories } from './categories-selectors';
import { PLUGINS } from '../components/rich-content-editor/plugins-constants';
import { TEXT_ALIGNMENT_CENTER } from '../constants/text-alignment';
import { FORUM_STRUCTURE_REGULAR } from '../../common/constants/forum-structure-toggle-types';
import { mapToAppSettings, SORT_BY_LAST_ACTIVITY } from '../constants/sorting';
import { EXPERIMENT_FILE_UPLOAD, EXPERIMENT_EMOJI_PLUGIN } from '../constants/experiments';
import { isExperimentEnabled } from './experiments-selectors';
import { getStyleParams } from '../../common/store/style-params/style-params-selectors';
import { CARD_SIZE_LARGE, CARD_SIZE_MEDIUM } from '../constants/card-sizes';

export const getIsHeaderEnabled = (state, style) => {
  return getAppSettingsValue({ style, key: IS_HEADER_ENABLED_PATH, fallback: true });
};

export const getHeaderHeight = (state, style) => {
  return getAppSettingsValue({ style, key: HEADER_HEIGHT_PATH, fallback: 250 });
};

export const getMainPageHeaderHeight = (state, style) => {
  return getAppSettingsValue({
    style,
    key: MAIN_PAGE_HEADER_HEIGHT_PATH,
    fallback: getHeaderHeight(state, style),
  });
};

export const getCategoryTextAlignment = (state, style) => {
  return getAppSettingsValue({
    style,
    key: CATEGORY_TEXT_ALIGNMENT_PATH,
    fallback: TEXT_ALIGNMENT_CENTER,
  });
};

export const getMainPageHeaderTextAlignment = (state, style) => {
  return getAppSettingsValue({
    style,
    key: MAIN_PAGE_TEXT_ALIGNMENT_PATH,
    fallback: getCategoryTextAlignment(state, style),
  });
};

export const getIsMainPageHeaderEnabled = (state, style) => {
  return getAppSettingsValue({
    style,
    key: IS_MAIN_PAGE_HEADER_ENABLED_PATH,
    fallback: getIsHeaderEnabled(state, style),
  });
};

export const getIsShowSubcategoriesEnabled = (state, style, { getMobileSettingsValue } = {}) => {
  return getAppSettingsValue({
    style,
    key: IS_SHOW_SUBCATEGORIES_ENABLED_PATH,
    fallback: false,
    state,
    getMobileSettingsValue,
  });
};

export const getIsForumStructureRegular = style => {
  return (
    getAppSettingsValue({ style, key: FORUM_STRUCTURE_PATH, fallback: FORUM_STRUCTURE_REGULAR }) ===
    FORUM_STRUCTURE_REGULAR
  );
};

// returns string value of sorting constant, example: 'sortByBestMach'
export const getPostSorting = style => {
  const numberValue = getAppSettingsValue({
    style,
    key: POST_SORTING_PATH,
    fallback: mapToAppSettings[SORT_BY_LAST_ACTIVITY],
  });
  return findKey(mapToAppSettings, s => s === String(numberValue));
};

export const getIsMainPageEnabled = (state, style) => {
  const isForumRegular = getIsForumStructureRegular(style);
  const totalCategories = getCategories(state).length;
  return isForumRegular && totalCategories > 1;
};

export const getIsCategorySelectEnabled = (state, style) => {
  const isForumFeed = !getIsForumStructureRegular(style);
  const totalCategories = getCategories(state).length;
  return isForumFeed && totalCategories > 1;
};

export const getIsLoginWidgetEnabled = (state, style, { getMobileSettingsValue } = {}) =>
  getAppSettingsValue({
    style,
    key: IS_LOGIN_WIDGET_ENABLED_PATH,
    fallback: !isMemberAreaActive(state),
    state,
    getMobileSettingsValue,
  });

export const getIsViewsCountEnabled = (state, style, { getMobileSettingsValue } = {}) =>
  getAppSettingsValue({
    style,
    key: IS_VIEWS_COUNT_ENABLED_PATH,
    fallback: true,
    state,
    getMobileSettingsValue,
  });

export const getIsListHashtagsEnabled = (state, style, { getMobileSettingsValue } = {}) =>
  getAppSettingsValue({
    style,
    key: IS_LIST_HASHTAGS_ENABLED_PATH,
    fallback: true,
    state,
    getMobileSettingsValue,
  });

export const getIsSocialSharingLinksEnabled = (state, style) =>
  getAppSettingsValue({
    style,
    key: IS_SOCIAL_SHARING_LINKS_ENABLED_PATH,
    fallback: true,
  });

export const getIsBadgesEnabled = (state, style, { getMobileSettingsValue } = {}) =>
  getAppSettingsValue({
    style,
    key: IS_BADGES_ENABLED_PATH,
    fallback: true,
    state,
    getMobileSettingsValue,
  });

export const getEnabledRcePlugins = (state, style) =>
  [
    getAppSettingsValue({ style, key: IS_RCE_IMAGE_ENABLED_PATH, fallback: true })
      ? PLUGINS.IMAGE
      : null,
    getAppSettingsValue({ style, key: IS_RCE_VIDEO_ENABLED_PATH, fallback: true })
      ? PLUGINS.VIDEO
      : null,
    getAppSettingsValue({ style, key: IS_RCE_DIVIDER_ENABLED_PATH, fallback: true })
      ? PLUGINS.DIVIDER
      : null,
    getAppSettingsValue({ style, key: IS_RCE_HTML_ENABLED_PATH, fallback: false })
      ? PLUGINS.HTML
      : null,
    getAppSettingsValue({ style, key: IS_RCE_CODE_BLOCK_ENABLED_PATH, fallback: true })
      ? PLUGINS.CODE_BLOCK
      : null,
    getAppSettingsValue({ style, key: IS_RCE_GIPHY_ENABLED_PATH, fallback: true })
      ? PLUGINS.GIPHY
      : null,
    isExperimentEnabled(state, EXPERIMENT_FILE_UPLOAD)
      ? getAppSettingsValue({ style, key: IS_RCE_FILE_UPLOAD_ENABLED_PATH, fallback: true })
        ? PLUGINS.FILE_UPLOAD
        : null
      : null,
    isExperimentEnabled(state, EXPERIMENT_EMOJI_PLUGIN)
      ? getAppSettingsValue({ style, key: IS_RCE_EMOJI_ENABLED_PATH, fallback: true })
        ? PLUGINS.EMOJI
        : null
      : null,
  ].filter(p => p !== null);

export const getIsFooterPostsEnabled = (state, style, { getMobileSettingsValue } = {}) => {
  return getAppSettingsValue({
    style,
    key: IS_FOOTER_POSTS_ENABLED_PATH,
    fallback: true,
    state,
    getMobileSettingsValue,
  });
};

export const getStyle = state => ({ styleParams: getStyleParams(state) });

export const getCardSize = (state, style) => {
  const cardSize = get(style, CARD_SIZE_PATH);
  return cardSize === CARD_SIZE_LARGE ? CARD_SIZE_MEDIUM : cardSize;
};
