import { CARD_BACKGROUND_COLOR_PATH } from '../constants/wix-params';
import withSettingsColor from './with-settings-color';

export default withSettingsColor({
  path: CARD_BACKGROUND_COLOR_PATH,
  propName: 'cardBackgroundColor',
  alpha: 1,
  siteColorFallback: 'color-1',
  siteColorAlpha: 1,
  fallbackColor: '#FFFFFF',
});
