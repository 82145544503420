import { flowRight, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import withDirection, { DIRECTIONS, withDirectionPropTypes } from 'react-with-direction';
import Overlay from 'react-overlays/lib/Overlay';
import BorderedTooltip from '../bordered-tooltip';
import withSettingsColor from '../../hoc/with-settings-color';
import { CARD_BACKGROUND_COLOR_PATH, APP_TEXT_COLOR_PATH } from '../../constants/wix-params';
import Tooltip from '../tooltip';
import styles from './icon-tooltip.scss';
import { connect } from '../../../common/components/runtime-context';
import { POSITION_REVERSE_MAP } from '../../constants/direction-reverse-map';

class IconTooltip extends Component {
  state = {
    isTooltipVisible: false,
  };

  componentWillUnmount() {
    this.handleMouseLeave();
  }

  handleMouseEnter = () => {
    this.timeout = setTimeout(() => {
      this.setState({
        isTooltipVisible: true,
      });
    }, 500);
  };

  handleMouseLeave = () => {
    clearTimeout(this.timeout);
    this.setState({
      isTooltipVisible: false,
    });
  };

  adjustPlacementForRTL(placement) {
    const { direction } = this.props;
    return direction === DIRECTIONS.RTL ? POSITION_REVERSE_MAP[placement] : placement;
  }

  render() {
    const {
      children,
      text,
      backgroundColor,
      borderColor,
      className,
      tooltipClassName,
      small,
      offset,
      textColor,
      tooltipFont,
      placement,
      ...props
    } = this.props;
    const tooltipClassNames = classNames(tooltipClassName, styles.tooltip);
    const tooltipStyles = {
      color: textColor,
      font: tooltipFont,
    };
    const selectedTooltip = small ? (
      <Tooltip small={small} className={tooltipClassNames} offset={offset} style={tooltipStyles}>
        {text}
      </Tooltip>
    ) : (
      <BorderedTooltip
        className={tooltipClassNames}
        style={{ ...tooltipStyles, borderColor, backgroundColor, fontSize: 12 }}
        placement={this.adjustPlacementForRTL(placement)}
        offset={offset}
      >
        {text}
      </BorderedTooltip>
    );
    const container =
      document.getElementById('modal-overlay') || document.getElementById('content-wrapper');
    return (
      <div
        className={className}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        {...props}
      >
        {children}
        <Overlay
          show={this.state.isTooltipVisible}
          placement={this.adjustPlacementForRTL(placement)}
          target={this}
          container={container}
        >
          {selectedTooltip}
        </Overlay>
      </div>
    );
  }
}

IconTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  backgroundColor: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  tooltipFont: PropTypes.string,
  className: PropTypes.string,
  tooltipClassName: PropTypes.string,
  small: PropTypes.bool,
  offset: PropTypes.number,
  placement: PropTypes.string,
  ...withDirectionPropTypes,
};

IconTooltip.defaultProps = {
  placement: 'top',
};

const mapRuntimeToProps = (state, ownProps, actions, { style }) => {
  const match = get(style, ['styleParams', 'fonts', 'post-contentFontMedium', 'value'], '').match(
    /font:(.*?);/,
  );
  return {
    tooltipFont: (match && match[1]) || '',
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withSettingsColor({
    path: CARD_BACKGROUND_COLOR_PATH,
    propName: 'backgroundColor',
    alpha: 1,
    siteColorFallback: 'color-1',
    siteColorAlpha: 1,
    fallbackColor: '#ffffff',
  }),
  withSettingsColor({
    path: APP_TEXT_COLOR_PATH,
    propName: 'borderColor',
    alpha: 0.2,
    siteColorFallback: 'color-5',
    siteColorAlpha: 0.2,
  }),
  withSettingsColor({
    path: APP_TEXT_COLOR_PATH,
    propName: 'textColor',
    alpha: 1,
    siteColorFallback: 'color-5',
  }),
  withDirection,
)(IconTooltip);
