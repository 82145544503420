export const EXPERIMENT_FILE_UPLOAD = 'specs.wixForum.FileUploadNew';
export const EXPERIMENT_RENDER_SEO_TAGS = 'specs.wixForum.RenderSeoTags';
export const EXPERIMENT_PRIVATE_CATEGORIES = 'specs.wixForum.PrivateCategories';
export const EXPERIMENT_POST_RATE_LIMITER = 'specs.wixForum.PostRateLimiter';
export const EXPERIMENT_SET_SEO_STATUS_CODE = 'specs.wixForum.SetSeoStatusCode';
export const EXPERIMENT_PAID_PLANS = 'specs.wixForum.PaidPlans';
export const EXPERIMENT_FORCE_USER_OPEN_SETTINGS = 'specs.wixForum.ForceUserOpenSettings';
export const EXPERIMENT_QA_CATEGORIES = 'specs.wixForum.QACategories';
export const EXPERIMENT_VIDEO_FILE_UPLOAD = 'specs.wixForum.VideoFileUpload';
export const EXPERIMENT_RELATED_POSTS = 'specs.wixForum.RelatedPosts';
export const EXPERIMENT_IMAGE_EXPAND = 'specs.wixForum.ImageExpand';
export const EXPERIMENT_NEW_OEMBED_SERVICE = 'specs.wixForum.NewOembedService';
export const EXPERIMENT_INVISIBLE_CATS = 'specs.wixForum.InvisibleCats';
export const EXPERIMENT_GLOBAL_LOADER = 'specs.wixForum.GlobalLoader';
export const EXPERIMENT_LOCAL_DRAFT = 'specs.wixForum.LocalDraft';
export const EXPERIMENT_WHO_LIKED = 'specs.wixForum.WhoLikedMe';
export const EXPERIMENT_EMOJI_PLUGIN = 'specs.wixForum.EmojiPlugin';
