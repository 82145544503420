export const PLUGINS = {
  IMAGE: 'image',
  VIDEO: 'video',
  LINK: 'link',
  HASHTAG: 'hashtag',
  DIVIDER: 'divider',
  HTML: 'html',
  MENTIONS: 'mentions',
  CODE_BLOCK: 'code_block',
  TEXT_COLOR: 'wix-rich-content-text-color',
  GIPHY: 'wix-draft-plugin-giphy',
  FILE_UPLOAD: 'wix-draft-plugin-file-upload',
  EMOJI: 'emoji',
};
