import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import UserAvatarImage from '../user-avatar-image';
import QuickUserInfo from '../quick-user-info';
import MemberCardAsync from '../../containers/member-card-async';
import ProfileLink from '../link/profile-link';
import RoleBadge from '../role-badge';
import styles from './avatar.scss';

function Avatar({
  type,
  user = {},
  date,
  showDate,
  showBadges,
  showStrippedBadges,
  isTinyBadge,
  truncateBadgeTitle,
  showMemberCard,
  showUserInfoInline,
  showFirstSeparator,
}) {
  const className = classNames(
    styles.container,
    'forum-text-color',
    { [styles[type]]: type },
    'avatar',
  );

  const wrapWithProfileLink = component => (
    <ProfileLink user={user} className={styles.link}>
      {component}
    </ProfileLink>
  );

  const wrapWithMemberCard = component => (
    <MemberCardAsync viewedMemberId={user.siteMemberId} className={styles.linkWrapper}>
      {component}
    </MemberCardAsync>
  );

  const avatarImage = <UserAvatarImage type={type} user={user} className={styles.noShrink} />;
  const userName = (
    <span
      className={classNames(styles.name, 'forum-text-color', 'forum-link-hover-color')}
      data-hook="avatar__name"
    >
      {user.name}
    </span>
  );
  const roleBadge = (
    <RoleBadge user={user} className={classNames('forum-icon-fill', styles.noShrink)} />
  );

  const renderUserInfo = () => (
    <QuickUserInfo
      user={user}
      date={date}
      showDate={showDate}
      showBadges={showBadges}
      isTinyBadge={isTinyBadge}
      hideBadgeBackground={showStrippedBadges}
      noMargin={
        type === Avatar.FOOTER_POST || type === Avatar.RECENT_POSTS_WIDGET || showUserInfoInline
      }
      truncateBadgeTitle={truncateBadgeTitle}
      showFirstSeparator={showFirstSeparator}
    />
  );

  const content = (
    <div className={className}>
      {avatarImage}
      <div className={styles.profileInfo}>
        <div className={styles.userName}>
          <div className={styles.user}>
            {userName}
            {showUserInfoInline && (
              <React.Fragment>
                {roleBadge}
                {renderUserInfo()}
              </React.Fragment>
            )}
          </div>
          {!showUserInfoInline && roleBadge}
        </div>
        {!showUserInfoInline && renderUserInfo()}
      </div>
    </div>
  );

  return showMemberCard
    ? wrapWithMemberCard(wrapWithProfileLink(content))
    : wrapWithProfileLink(content);
}

Avatar.SMALL = 'small';
Avatar.LARGE = 'large';
Avatar.MEDIUM = 'medium';
Avatar.FOOTER_POST = 'footerPost';
Avatar.POST_PAGE = 'postPage';
Avatar.RECENT_POSTS_WIDGET = 'recentPostsWidget';
Avatar.WHO_LIKED = 'whoLiked';

Avatar.propTypes = {
  user: PropTypes.object.isRequired,
  type: PropTypes.string,
  date: PropTypes.string,
  showDate: PropTypes.bool,
  showBadges: PropTypes.bool,
  showStrippedBadges: PropTypes.bool,
  isTinyBadge: PropTypes.bool,
  compact: PropTypes.bool,
  showMemberCard: PropTypes.bool,
  showUserInfoInline: PropTypes.bool,
  showFirstSeparator: PropTypes.bool,
};

Avatar.defaultProps = {
  showBadges: true,
  showMemberCard: true,
  showInlineBadges: false,
  showFirstSeparator: false,
};

export default Avatar;
