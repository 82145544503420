import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../../app/components/button';
import Link from '../../../app/components/link/internal-link';
import EmptyState from './empty-state';
import withPermissions from '../../../app/hoc/with-permissions';
import withTranslate from '../../../app/hoc/with-translate';

const NoPosts = ({ t, category, can }) => {
  const canCreatePost = !category || can('create-post', 'category', category);
  const link = category ? `/${category.slug}` : '/';

  const button = !canCreatePost ? null : (
    <Button component={Link} to={link}>
      {t('recent-posts-widget.empty-state.no-posts.button')}
    </Button>
  );

  const title = canCreatePost
    ? t('recent-posts-widget.empty-state.no-posts.title')
    : t('recent-posts-widget.empty-state.no-posts.cant-create.title');

  const content = canCreatePost
    ? t('recent-posts-widget.empty-state.no-posts.content')
    : t('recent-posts-widget.empty-state.no-posts.cant-create.content');

  return <EmptyState title={title} content={content} button={button} />;
};

NoPosts.propTypes = {
  t: PropTypes.func,
  category: PropTypes.object,
  can: PropTypes.func,
};

export default flowRight(withPermissions, withTranslate)(NoPosts);
