import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import FluidAvatarImage from '../fluid-avatar-image';
import styles from './avatar-image.scss';

const AvatarImage = ({ component: Component, type, image, className, ...otherProps }) => {
  const componentProps =
    Component === AvatarImage.defaultProps.component ? omit(otherProps, 'user') : otherProps;

  return (
    <Component
      className={classNames(styles.image, className, styles[type], 'avatar-image')}
      {...componentProps}
    >
      <FluidAvatarImage image={image} />
    </Component>
  );
};

AvatarImage.MEDIUM = 'medium';
AvatarImage.LARGE = 'large';
AvatarImage.EXTRA_LARGE = 'extraLarge';
AvatarImage.PROMOTE = 'promote';
AvatarImage.WIDGET = 'widget';
AvatarImage.COMMENT = 'comment';
AvatarImage.COMMENT_SEARCH = 'commentSearch';
AvatarImage.POST_PAGE = 'postPage';
AvatarImage.FOOTER_POST = 'footerPost';
AvatarImage.RECENT_POSTS_WIDGET = 'recentPostsWidget';
AvatarImage.WHO_LIKED = 'whoLiked';

AvatarImage.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
};

AvatarImage.defaultProps = {
  component: 'span',
  type: AvatarImage.MEDIUM,
};

export default AvatarImage;
